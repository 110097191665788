<template>
    <div class="sms-sim">
        <div class="sim-container">
            <div class="screen">
                <div class="name-bar">
                    <div class="name"> I'm Okay App </div>
                </div>
                <div class="message-screen">
                    <div class="timestamp"><span><b>Today {{ $moment().format('h:mm A') }}</b></span></div>
                    <template v-for="(item, idx) in msgs">
                    <div class="sender-text" :key="'m'+idx" v-html="item.content"></div>
                    </template>
                    <!-- <div class="receiver-text">
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod. Hey CodePen! Tempor incididunt ut labore et dolore magna aliqua. a aliqua. </p>
                    </div> -->
                    <!-- <div class="timestamp"><span><b>Today</b> 10:59 AM</span></div>
                    <div class="sender-text">
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. a aliqua. </p>
                    </div>
                    <div class="receiver-text">
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. a aliqua. </p>
                    </div>
                    <div class="timestamp"><span><b>Today</b> 11:39 AM</span></div>
                    <div class="sender-text">
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. a aliqua. </p>
                    </div>
                    <div class="receiver-text">
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. a aliqua. </p>
                    </div> -->
                </div>
                <!-- <div class="input-bar"> 
                    <i class="fa fa-camera"></i>
                    <input id="input-text" value="" placeholder="iMessage" />
                    <div class="input-bar-button" id="send"> <span>Send</span> </div>
                </div> -->
            </div>
        </div>
    </div>
</template>
<script>

export default {

    name: 'SMSSim',
    props: {
        msgs: {
            required: false,
            type: Array
        }
    },
    computed: {
    },
    data: () => ({
    }),
    methods: {
    },
    mounted(){
    },
    watch: {
    }
}
</script>
